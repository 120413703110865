import $ from 'jquery';
const jQuery = $;
window.jQuery = $;
require('popper.js');
require('bootstrap');
require('waypoints/lib/jquery.waypoints');
require('./js/vendor/jquery.mb.YTPlayer-3.2.9/dist/jquery.mb.YTPlayer.js');

$(document).ready(function () {

    /***************** Waypoints ******************/

    // const waypoint = new Waypoint({
    //     element: document.getElementsByClassName('wp1'),
    //     handler: function(direction) {
    //         $('.wp1').addClass('animated fadeInLeft');
    //     },
    //     offset: '75%'
    // });
    $('.wp1').waypoint(function () {
        $('.wp1').addClass('animated fadeInLeft');
    }, {
        offset: '75%'
    });
    $('.wp2').waypoint(function () {
        $('.wp2').addClass('animated fadeInRight');
    }, {
        offset: '75%'
    });
    $('.wp3').waypoint(function () {
        $('.wp3').addClass('animated fadeInLeft');
    }, {
        offset: '75%'
    });
    $('.wp4').waypoint(function () {
        $('.wp4').addClass('animated fadeInRight');
    }, {
        offset: '75%'
    });
    $('.wp5').waypoint(function () {
        $('.wp5').addClass('animated fadeInLeft');
    }, {
        offset: '75%'
    });
    $('.wp6').waypoint(function () {
        $('.wp6').addClass('animated fadeInRight');
    }, {
        offset: '75%'
    });
    $('.wp7').waypoint(function () {
        $('.wp7').addClass('animated fadeInUp');
    }, {
        offset: '75%'
    });
    $('.wp8').waypoint(function () {
        $('.wp8').addClass('animated fadeInLeft');
    }, {
        offset: '75%'
    });
    $('.wp9').waypoint(function () {
        $('.wp9').addClass('animated fadeInRight');
    }, {
        offset: '75%'
    });

    /***************** Initiate Flexslider ******************/
    // $('.flexslider').flexslider({
    //     animation: "slide"
    // });

    /***************** Initiate Fancybox ******************/

    // $('.single_image').fancybox({
    //     padding: 4
    // });
    //
    // $('.fancybox').fancybox({
    //     padding: 4,
    //     width: 1000,
    //     height: 800
    // });

    /***************** Tooltips ******************/
    // $('[data-toggle="tooltip"]').tooltip();

    /***************** Nav Transformicon ******************/

    /* When user clicks the Icon */
    $('.nav-toggle').click(function () {
        $(this).toggleClass('active');
        $('.header-nav').toggleClass('open');
        event.preventDefault();
    });
    /* When user clicks a link */
    $('.header-nav li a').click(function () {
        $('.nav-toggle').toggleClass('active');
        $('.header-nav').toggleClass('open');

    });

    /***************** Header BG Scroll ******************/

    $(function () {
        $(window).scroll(function () {
            const scroll = $(window).scrollTop();

            if (scroll >= 20) {
                $('section.navigation').addClass('fixed');
                $('header').css({
                    "border-bottom": "none",
                    // "padding": "25px 0"
                });
                $('header .member-actions').css({
                    "top": "18px",
                });
                $('header .navicon').css({
                    "top": "34px",
                });
            } else {
                $('section.navigation').removeClass('fixed');
                $('header').css({
                    "border-bottom": "solid 1px rgba(255, 255, 255, 0.2)",
                    // "padding": "50px 0"
                });
                $('header .member-actions').css({
                    // "top": "41px",
                });
                $('header .navicon').css({
                    "top": "48px",
                });
            }
        });
    });
    setTimeout(() => $(window).scroll(), 1);

    /***************** Smooth Scrolling ******************/

    $(function () {
        $('a[href^="#"]').click(function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                const target = $(this.hash).length ? $(this.hash) : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 90
                    }, 2000);
                    return false;
                }
            }
        });
    });

    /********************** Social Share buttons ***********************/
    // var share_bar = document.getElementsByClassName('share-bar');
    // var po = document.createElement('script');
    // po.type = 'text/javascript';
    // po.async = true;
    // po.src = 'https://apis.google.com/js/platform.js';
    // var s = document.getElementsByTagName('script')[0];
    // s.parentNode.insertBefore(po, s);
    //
    // // for (var i = 0; i < share_bar.length; i++) {
    // //     var html = '<iframe allowtransparency="true" frameborder="0" scrolling="no"' +
    // //         'src="https://platform.twitter.com/widgets/tweet_button.html?url=' + encodeURIComponent(window.location) + '&amp;text=' + encodeURIComponent(document.title) + '&amp;via=ramswarooppatra&amp;hashtags=ramandantara&amp;count=horizontal"' +
    // //         'style="width:105px; height:21px;">' +
    // //         '</iframe>' +
    // //
    // //         '<iframe src="//www.facebook.com/plugins/like.php?href=' + encodeURIComponent(window.location) + '&amp;width&amp;layout=button_count&amp;action=like&amp;show_faces=false&amp;share=true&amp;height=21&amp;appId=101094500229731&amp;width=150" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:150px; height:21px;" allowTransparency="true"></iframe>' +
    // //
    // //         '<div class="g-plusone" data-size="medium"></div>';
    // //
    // //     // '<iframe src="https://plusone.google.com/_/+1/fastbutton?bsv&amp;size=medium&amp;url=' + encodeURIComponent(window.location) + '" allowtransparency="true" frameborder="0" scrolling="no" title="+1" style="width:105px; height:21px;"></iframe>';
    // //
    // //     share_bar[i].innerHTML = html;
    // //     share_bar[i].style.display = 'inline-block';
    // // }

    /********************** Embed youtube video *********************/
    window.videoReady = false;
    $('.player').YTPlayer({
        onError: function(e) {
            console.error('YTPlayer error', e)
        },
        onReady: function() {
            // console.log('ready ready')
            window.videoReady = true;
        }
    })
    setTimeout(() => {
        // console.log('poking ytplayer', window.videoReady);
        if (!window.videoReady) {
            $('.player').YTPlayer();
        }
    }, 3000);

    /********************** Toggle Map Content **********************/
    $('.btn-show-map').click(function () {
        $('#map-content').toggleClass('toggle-map-content');
        $('#btn-show-content').toggleClass('toggle-map-content');
    });
    $('#btn-show-content').click(function () {
        $('#map-content').toggleClass('toggle-map-content');
        $('#btn-show-content').toggleClass('toggle-map-content');
    });


    /********************** RSVP **********************/
    const scriptURL = 'https://script.google.com/macros/s/AKfycbyiuwggUg7oOqKd-pXWEGXlt7rJM3EgydONZpvKM-Y66Z3wSgU/exec';
    const alertWrapper = $('#alert-wrapper');
    const $rsvpForm = $('#rsvp-form');
    const $rsvpBtn = $rsvpForm.find('.rsvp-btn');
    $rsvpForm.on('submit', event => {
        event.preventDefault();
        $rsvpBtn.prop('disabled', true);
        const data = $(event.target).serialize();

        alertWrapper.html(alert_markup('info', '<strong>Just a sec!</strong> We are saving your details.'));

        jQuery.ajax({
            crossDomain: true,
            url: scriptURL,
            data: data,
            method: 'POST',
        })
            .done(function (response) {
                const isGoing = jQuery('.form-check-input').map((i, el) => el.checked).get().some(a => a);
                const msg = isGoing ? 'See you there :)' : 'We\'re sorry you can\'t make it :(';
                alertWrapper.html(alert_markup('success', `<strong>Saved! ${msg}</strong>`));
                jQuery('#rsvp-form .hide-on-submit').slideUp();
            })
            .fail(function (response) {
                alertWrapper.html(alert_markup('danger', '<strong>Sorry!</strong> There is some issue with the server. Please try again later.'));
                $rsvpBtn.prop('disabled', false);
            })
    });

    const plusoneField = $('#rsvp-plusone-field');
    $('#rsvp-plusone-check').on('change', event => {
        if (event.target.checked) {
            plusoneField.removeClass('d-none');
        } else {
            plusoneField.addClass('d-none');
        }
    });

    const childrenField = $('#rsvp-children-field');
    $('#rsvp-children-check').on('change', event => {
        if (event.target.checked) {
            childrenField.removeClass('d-none');
        } else {
            childrenField.addClass('d-none');
        }
    });

    const $attendanceFields = $rsvpForm.find('input.attendance');

    $attendanceFields.on('change', event => {
        if ($attendanceFields.is(':checked')) {
            $rsvpBtn.text('Count me in!')
        } else {
            $rsvpBtn.text('We can\'t make it :(')
        }
    });

    $('#venue-map-carousel').on('slide.bs.carousel', event => {
        const place = event.relatedTarget.attributes['data-placename'].value;
        const newPos = mapMarkers[place].getPosition();
        const offsetPos = offsetCenter(newPos, 0, -150);
        gmap.panTo(offsetPos);
    })
});

/********************** Extras **********************/

// Google map
window.initMap = function () {
    const trajineras = {lat:19.2517292, lng: -99.0957534};
    const church = {lat: 19.3443312, lng: -99.1944633};
    const reception = {lat:19.3488003, lng: -99.1843978};
    const brunch = {lat:19.4343336, lng: -99.1423517};
    const map = new google.maps.Map(document.getElementById('map-canvas'), {
        zoom: 15,
        center: church,
        scrollwheel: false,
        disableDefaultUI: true
    });
    window.gmap = map;

    window.mapMarkers = {
        trajineras: new google.maps.Marker({position: trajineras, map: map, label: 'Trajineras'}),
        church: new google.maps.Marker({position: church, map: map, label: 'Church'}),
        party: new google.maps.Marker({position: reception, map: map, label: 'Reception'}),
        brunch: new google.maps.Marker({position: brunch, map: map, label: 'Brunch'})
    };
};

function offsetCenter(latlng, offsetx, offsety) {
    const scale = Math.pow(2, gmap.getZoom());
    const worldCoordinateCenter = gmap.getProjection().fromLatLngToPoint(latlng);
    const pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) ||0);
    const worldCoordinateNewCenter = new google.maps.Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
    );

    return gmap.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
}

// alert_markup
function alert_markup(alert_type, msg) {
    return '<div class="alert alert-' + alert_type + '" role="alert">' + msg + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span>&times;</span></button></div>';
}
